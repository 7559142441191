<template>
<form id="signUp">
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3">
        <label class="control-label">Username</label>
        <input type="text" v-model="username" class="form-control" placeholder="Username" />
    </div>
    <div class="mb-3">
        <label class="control-label">Password</label>
        <input type="password" v-model="password" class="form-control" placeholder="Password" />
    </div>
    <div class="mb-3">
        <label class="control-label">Confirm Password</label>
        <input type="password" v-model="confirmPassword" class="form-control" placeholder="Password" />
    </div>
    <div class="mb-3">
        <label class="control-label">Email</label>
        <input type="text" v-model="email" class="form-control" placeholder="Email" />
    </div>
    <div class="mb-3">
        <label class="control-label">Invite Code</label>
        <input type="text" v-model="code" class="form-control" placeholder="Invite Code" />
    </div>
    <button @click="signUp" class="btn btn-primary">Sign Up</button>
    <span class="ms-5">Have account? Go to <router-link to="/?#/login">Sign In</router-link></span>
</form>
</template>

<script>
import axios from 'axios';

var systemUrl = "http://192.168.1.48:8080/";
//var systemUrl = "/";

export default {
  name: 'SignUp',
  data: function() {
    return {
      username: "",
      password: "",
      submitted: false,
      alertContent: "",
      alertClass: "alert-danger"
    };
  },
  methods: {
    signUp() {
      if (this.submitted) {
          return
      }
      if (this.username && this.password && this.confirmPassword && this.email && this.code && this.password === this.confirmPassword) {
        this.submitted = true;
        axios.post("/token/signUp", {
            username: this.username, 
            password: this.password,
            email: this.email,
            code: this.code
        }, { baseURL: systemUrl }).then(() => {
            this.alertContent = "Your account has been saved successfully!";
            this.alertClass = "alert-success";
            this.submitted = false;
        }).catch((error) => {
            this.alertContent = error.response ? error.response.data : error;
            this.alertClass = "alert-danger";
            this.submitted = false;
        })
      } else {
        this.alertContent = "Please make sure inputs are correct and try again!";
        this.alertClass = "alert-danger";
      }
    }
  }
};
</script>

<style scoped>
#signUp {
    text-align: left;
    width: 60%;
    margin-left: 20%;
    border: 1px solid #ced4da;
    border-radius: 3px;
    padding: 35px;
}
</style>
